<template>
  <div class="home">
    <img class="logoimg" alt="logo" src="../assets/logo.png">
    <h1>记得泡泡 支持</h1>
    <h3 style="color: #FCCCD4; padding-bottom: 100px;" >Remember Bubble Support</h3>
    <div class="card-container">
      <Card icon="what.png" title="这是什么" description="记得泡泡是一款类似todo的app但是它非常简单" link="https://pixelcard.dmxx.ltd/" linkText="我要使用"/>
      <Card icon="mini.png" title="极简风" description="极简到什么地步呢...只有一个新建泡泡页面跟泡泡详细页面" link="https://pixelcard.dmxx.ltd/" linkText="极简人福利"/>
      <Card icon="tool.png" title="麻雀虽少 五脏俱全" description="新建todo事件 以及todo事件时间到了给予推送 该有的一样也不缺" link="https://pixelcard.dmxx.ltd/" linkText="啥都有"/>
      <Card icon="clean.png" title="不用担心堆积" description="当推送完成或者到达泡泡的时间以后 相应的泡泡会自动消失 再也不用担心任务堆积如山" link="https://pixelcard.dmxx.ltd/" linkText="真贴心"/>
      <Card icon="thank.png" title="感谢使用" description="当你使用这款app有任何建议或者想法欢迎与我沟通" link="https://pixelcard.dmxx.ltd/" linkText="hello开发者"/>
    </div>
    <SearchBar/>
    <div class="footer">
      <a class="footerText">Copyright @ 2023 Damien</a>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'HomeView',
  components: {
    Card,
    SearchBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}
.logoimg {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footerText{
  text-decoration: none;
  color: #FCCCD4;
  padding-top: 100px;
}
</style>